import { currencyFormatter } from '../helper/functions';

// Color Select
class CollectionColorSwatch extends HTMLElement {
  constructor() {
    super();
    this.handle = this.getAttribute('handle');

    this.productCard = this.closest('.product-card');
    if (this.productCard) {
      this.image = this.productCard.querySelector('.primary-image');
      this.hoverImage = this.productCard.querySelector('.hover-image');
      this.urls = this.productCard.querySelectorAll('.url');
      this.productTitle = this.productCard.querySelector('.title');
      this.price = this.productCard.querySelector('.price__regular');
      this.compareAtPrice = this.productCard.querySelector('.price__compare');
      this.arrows = this.productCard.querySelector('.splide__arrows');

      // Quickshop products
      this.variantContainer = this.productCard.querySelector('.quickshop-product-variants'); // Container for the variants
      if (this.variantContainer) {
        this.blockID = this.variantContainer.getAttribute('data-block') || ''; // Block ID for the variant container
        this.addToCartButton = this.productCard.querySelector('.js-atc'); // Add to cart button
      }
    }

    this.addEventListener('change', this.onColorUpdate.bind(this));
  }

  onColorUpdate(event) {
    if (event) {
      event.preventDefault();
    }

    fetch(`/products/${this.handle}.js`)
      .then((response) => response.json())
      .then((product) => {
        this.updateProductDetails(product);
        this.updateVariants(product);

        // Find the first available variant or use the first variant as a fallback
        const firstAvailableVariant = product.variants.find((v) => v.available) || product.variants[0];
        this.updateBuyButton(firstAvailableVariant);
      })
      .catch((error) => {
        console.error('Error updating product details:', error);
      });
  }

  updateProductDetails(product) {
    const url = product.url;
    const firstImage = product.images[0] + '&width=300';
    const secondImage = product.images.length > 1 ? product.images[1] + '&width=300' : firstImage;
    const title = product.title;
    const price = product.price / 100;
    const comparePrice = product.compare_at_price ? product.compare_at_price / 100 : null;

    this.urls.forEach((anchor) => anchor.setAttribute('href', url));
    this.image.setAttribute('src', firstImage);
    this.image.setAttribute('srcset', firstImage);

    if (this.hoverImage && secondImage) {
      this.hoverImage.setAttribute('src', secondImage);
      this.hoverImage.setAttribute('srcset', secondImage);
    } else if (this.hoverImage) {
      this.hoverImage.setAttribute('src', firstImage);
      this.hoverImage.setAttribute('srcset', firstImage);
    }

    this.productTitle.textContent = title;
    this.price.textContent = currencyFormatter.format(price);

    if (comparePrice) {
      this.compareAtPrice.querySelector('s').textContent = currencyFormatter.format(comparePrice);
      this.compareAtPrice.classList.remove('hidden');
      this.price.style.color = 'rgba(244,121,92)';
    } else {
      this.compareAtPrice.classList.add('hidden');
      this.price.style.color = 'black';
    }

    this.arrows.classList.remove('animate-bounce');
  }

  // Quickshop products
  updateVariants(product) {
    if (this.variantContainer) {
      const blockID = this.blockID;
      this.variantContainer.innerHTML = '';
      let firstAvailableChecked = false; // Flag to mark the first available variant

      product.variants.forEach((variant) => {
        const variantId = variant.id;
        const variantTitle = variant.title.split('/').pop();

        // Create the radio input for the variant
        const radioInput = document.createElement('input');
        radioInput.type = 'radio';
        radioInput.id = `variant-${variantId}-${blockID}`;
        radioInput.name = 'variant';
        radioInput.value = variantId;
        radioInput.classList.add('hidden', 'product-variant-radio', 'peer');

        // Create the label for the variant
        const label = document.createElement('label');
        label.htmlFor = `variant-${variantId}-${blockID}`;
        label.textContent = variantTitle;
        label.classList.add('quickshop-product-variant-item', 'text-13px', 'lg:text-14px');

        if (variant.available) {
          label.classList.add('text-black', 'hover:border-b', 'hover:border-black', 'cursor-pointer');
          if (!firstAvailableChecked) {
            radioInput.checked = true;
            radioInput.setAttribute('checked', 'checked');
            radioInput.setAttribute('aria-checked', 'true');
            label.classList.add('border-black', 'border-b');
            firstAvailableChecked = true; // Ensure only the first available variant is checked
          }
        } else {
          label.classList.add('text-gray', 'line-through', 'cursor-not-allowed');
          radioInput.disabled = true;
        }

        this.variantContainer.appendChild(radioInput);
        this.variantContainer.appendChild(label);
      });
    }
  }

  updateBuyButton(variant) {
    if (this.addToCartButton) {
      const variantInput = this.productCard.querySelector('.product-variant-id');
      variantInput.value = variant.id.toString(); // Update the hidden input with the variant ID
      variantInput.disabled = !variant.available; // Disable input if the variant is not available

      this.addToCartButton.disabled = !variant.available; // Disable the button if the variant is not available
      this.addToCartButton.textContent = variant.available ? 'Add To Bag' : 'Sold Out';
      this.addToCartButton.classList.toggle('cursor-not-allowed', !variant.available);
      this.addToCartButton.classList.toggle('text-gray', !variant.available);
      this.addToCartButton.classList.toggle('text-black', variant.available);
      this.addToCartButton.classList.toggle('cursor-pointer', variant.available);
    }
  }
}

if (!window.customElements.get('collection-swatch-selector')) {
  customElements.define('collection-swatch-selector', CollectionColorSwatch);
}
